
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { required } from "@/cms-services/consts";
import DataList from "@/components/common/data/list.vue";
import { Filter } from "@/cms-services/filter";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
const visibleProperties = [
  "firstName",
  "lastName",
  "id",
  "createDate",
  "instanceStateId",
];
@Component({
  components: { DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  @Ref("dataList") dataList!: DataList;

  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    className: "ClientManager",
    config: {},
  });

  filterDataSource: ListDataSource = new ListDataSource({
    className: "clientManagerFilter",
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        new Filter("сlientManagerId", +this.$route.params.id),
      ]),
    },
  });
  $message: any;

  model: any = {
    filter: "",
    clientManagerId: +this.$route.params.id,
  };

  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Фильтр",
        type: "text",
      },
      name: "filter",
      validations: [required()],
    },
  ];

  get caption() {
    return (
      this.dataSource.model?.lastName + " " + this.dataSource.model?.firstName
    );
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
        exact: true,
      },
      {
        text: "Клиентские менеджеры",
        to: "/manage/crm/clientmanagers",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  get visibleProperties(): any {
    const properties = this.dataSource.metadata?.properties ?? [];
    const cb = (p) => visibleProperties.some((n) => p.name === n);
    return properties.filter(cb);
  }

  async add() {
    const model = await this.dataEditor.update(this.model);
    if (!model) return;

    try {
      await this.filterDataSource.add(model);
      if (this.filterDataSource.items.length == 1) {
        await this.dataList.refreshData();
      }
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }
}
